import * as React from 'react';
import styled, { ResolvedThemeInterface, StyledComponentClass, withProps } from '../styled-components';
const directionMap = {
  left: '90deg',
  right: '-90deg',
  up: '-180deg',
  down: '0'
};

class IntShelfIcon extends React.PureComponent<{
  className?: string;
  float?: 'left' | 'right';
  size?: string;
  color?: string;
  direction: 'left' | 'right' | 'up' | 'down';
  style?: React.CSSProperties;
}> {
  render() {
    return <svg className={this.props.className} style={this.props.style} version="1.1" viewBox="0 0 24 24" x="0" xmlns="http://www.w3.org/2000/svg" y="0">
        <polygon points="17.3 8.3 12 13.6 6.7 8.3 5.3 9.7 12 16.4 18.7 9.7 " />
      </svg>;
  }

}

export const ShelfIcon = styled(IntShelfIcon)(["height:", ";width:", ";vertical-align:middle;float:", ";transition:transform 0.2s ease-out;transform:rotateZ(", ");polygon{fill:", ";}"], props => props.size || '18px', props => props.size || '18px', props => props.float || '', props => directionMap[props.direction || 'down'], props => props.color && props.theme.colors[props.color] || props.color);
export const Badge = withProps<{
  type: string;
}>(styled.span)`
  display: inline-block;
  padding: 0 5px;
  margin: 0;
  background-color: ${props => props.theme.colors[props.type]};
  color: white;
  font-size: ${props => props.theme.code.fontSize};;
  vertical-align: text-top;
`;