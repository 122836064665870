import * as React from 'react';
import styled, { media, ResolvedThemeInterface, StyledComponentClass } from '../styled-components';
export const MiddlePanel = styled.div(["width:calc(100% - ", ");padding:", "px;", ";"], props => props.theme.rightPanel.width, props => props.theme.spacingUnit * 2, media.lessThan('medium')`
    width: 100%;
  `);
export const RightPanel = styled.div(["width:", ";color:#fafbfc;background-color:", ";padding:", "px;", ";"], props => props.theme.rightPanel.width, props => props.theme.rightPanel.backgroundColor, props => props.theme.spacingUnit * 2, media.lessThan('medium')`
    width: 100%;
  `);
export const DarkRightPanel = RightPanel.extend`
  background-color: ${props => props.theme.rightPanel.backgroundColor};
`;
export const Row = styled.div(["display:flex;width:100%;", ";"], media.lessThan('medium')`
    flex-direction: column;
  `);