import { transparentize } from 'polished';
import * as React from 'react';
import styled, { extensionsHook, ResolvedThemeInterface, StyledComponentClass, withProps } from '../styled-components';
import { deprecatedCss } from './mixins';
export const PropertiesTableCaption = styled.caption(["text-align:right;font-size:0.9em;font-weight:normal;color:", ";"], props => transparentize(0.6, props.theme.colors.text));
export const PropertyCell = styled.td(["border-left:1px solid ", ";box-sizing:border-box;position:relative;padding:10px 10px 10px 0;tr:first-of-type > &,tr.last > &{border-left-width:0;background-position:top left;background-repeat:no-repeat;background-size:1px 100%;}tr:first-of-type > &{background-image:linear-gradient( to bottom,transparent 0%,transparent 22px,", " 22px,", " 100% );}tr.last > &{background-image:linear-gradient( to bottom,", " 0%,", " 22px,transparent 22px,transparent 100% );}tr.last + tr > &{border-left-color:transparent;}tr:only-child > &{background:none;border-left-color:transparent;}"], props => props.theme.schemaView.linesColor, props => props.theme.schemaView.linesColor, props => props.theme.schemaView.linesColor, props => props.theme.schemaView.linesColor, props => props.theme.schemaView.linesColor);
export const PropertyCellWithInner = PropertyCell.extend`
  padding: 0;
`;
export const PropertyNameCell = withProps<{
  kind?: string;
}>(PropertyCell.extend)`
  vertical-align: top;
  line-height: 20px;
  white-space: nowrap;
  font-size: 0.929em;
  font-family: ${props => props.theme.headingsFont.family};

  &.deprecated {
    ${deprecatedCss};
  }

  ${({
  kind
}) => kind !== 'field' ? 'font-style: italic' : ''};

  ${extensionsHook('PropertyNameCell')};
`;
export const PropertyDetailsCell = styled.td(["border-bottom:1px solid #9fb4be;padding:10px 0;width:", ";box-sizing:border-box;tr.expanded &{border-bottom:none;}"], props => props.theme.schemaView.defaultDetailsWidth);
export const PropertyBullet = styled.span(["color:", ";font-family:", ";margin-right:10px;&::before{content:'';display:inline-block;vertical-align:middle;width:10px;height:1px;background:", ";}&::after{content:'';display:inline-block;vertical-align:middle;width:1px;background:", ";height:7px;}"], props => props.theme.schemaView.linesColor, props => props.theme.code.fontFamily, props => props.theme.schemaView.linesColor, props => props.theme.schemaView.linesColor);
export const InnerPropertiesWrap = styled.div(["padding:", ";"], ({
  theme
}) => theme.schemaView.nestingSpacing);
export const PropertiesTable = styled.table(["border-collapse:separate;border-radius:3px;font-size:", ";border-spacing:0;width:100%;> tr{vertical-align:middle;}& ", ",& ", " ", " ", ",& ", " ", " ", " ", " ", "{margin:", ";margin-right:0;background:#f0f0f0;}& ", " ", ",& ", " ", " ", " ", ",& ", " ", " ", " ", " ", " ", "{background:#ffffff;}"], props => props.theme.baseFont.size, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, ({
  theme
}) => theme.schemaView.nestingSpacing, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap, InnerPropertiesWrap);